@import "../variables.scss";

.detailsCell {
  max-width: 180px;
  word-break: break-word;

  p {
    line-height: 15px;
    margin-bottom: 8px;
    color: $table-body-secondary-color;
  }

  .primary {
    color: $table-body-color;
  }

  .small {
    line-height: 13px;
  }
}

.exemptionStatus {
  display: flex;
  align-items: center;
  padding-bottom: 16px;

  p {
    margin: 0 0 0 4px;
  }
}

.viewButton,
.editButton {
  padding: 0 !important;
  margin: 16px 0 0 0 !important;
  width: auto !important;
  min-width: 0 !important;
  min-height: 0 !important;
}

.chatButton {
  padding: 0 !important;
  margin: 16px 0 0 20px !important;
  width: auto !important;
  min-width: 0 !important;
  min-height: 0 !important;
}

.statusCell {
  display: flex;
  justify-content: center;

  path {
    color: rgb(139, 195, 74);
  }

  .warn path {
    fill: rgb(244, 67, 54);
  }
}

.menuItem {
  padding: 0 !important;
  height: auto !important;
  box-shadow: inset 1px 1px 0px #ddd;
}

.autoComplete {
  position: relative;
  z-index: 99;
}

.gpDontContactIcon {
  path {
    color: rgb(244, 67, 54);
  }
}

.gpUnknownIcon {
  path {
    color: #ff9900;
  }
}

.gpOKIcon {
  path {
    color: rgb(139, 195, 74);
  }
}

.greenIcon {
  path {
    color: rgb(139, 195, 74);
  }
}

.redIcon {
  path {
    color: rgb(209, 50, 66);
  }
}

.cardList {
  .clCard {
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;
    padding: 16px;
    font-size: 13px;
    cursor: pointer;
  }

  .clTransparent {
    opacity: 0.4;
  }

  .clRadioLabel {
    margin: 0 16px 0 0 !important;
  }

  .clRadio {
    margin: 0 !important;
    padding: 0 !important;
    width: 18px;
    height: 18px;
  }

  .clCheckbox {
    margin: 0 16px 0 0 !important;
    width: 18px;
    height: 18px;
  }
}

.mcsItemWarnIcon {
  color: rgb(209, 50, 66);

  path {
    fill: rgb(244, 67, 54);
  }

  svg,
  span {
    vertical-align: middle;
  }

  span {
    margin-left: 8px;
  }
}

.t_reason_option {
  color: $modal-body-secondary-color;
}
