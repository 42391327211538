.header{
  display: flex;  
  font-size: 80%;
}

.column {
  flex-grow: 1;
}

.column:last-child{
  flex-grow: 5;
}

.row {
  padding: 3px 3px 10px 3px;
}