@import "./variables.scss";
@import "./reset.scss";

* {
  box-sizing: border-box;
}

html,
body,
body > div:first-child,
#root,
#root > div {
  height: 100%;
  top: 0;
}

/* TYPOGRAPHY */

html {
  font-size: calc(14px + (16 - 14) * (100vw - 800px) / (1400 - 800) * 0.618) !important;
  display: table;
  width: 100%;
}

.root {
  width: 100%;
  overflow: auto;
}

h1 {
  font-size: 205%;
  padding-bottom: 20px;
}
h2 {
  font-size: 185%;
  padding-bottom: 16px;
}
h3 {
  font-size: 155%;
  padding-bottom: 10px;
}
h4 {
  font-size: 112%;
  padding-bottom: 5px;
}

body {
  font-family: Roboto, sans-serif;
  background: $standard-grey;
  color: $primary-font-color;
}

th,
td {
  vertical-align: middle;
}

strong {
  font-weight: 600;
}

.inProgress {
  display: flex;
  min-height: 400px;
  width: 100%;
  justify-content: center;
  align-items: center;

  > div {
    margin: 10px;
    color: rgb(38, 166, 154);
    text-shadow: 0 0px 1px rgba(38, 166, 154, 0.6);
  }
  circle {
    stroke: rgb(38, 166, 154) !important;
  }
}

.errorContent {
  display: block;
  background: $standard-grey;
  top: 57px;
  position: relative;
  height: 600px;
  padding: 100px;
  text-align: center;
  width: calc(100% - 17px) !important;
}

.row td,
thead th {
  white-space: normal !important;
  line-height: 130%;
  padding: 16px 0px 16px 24px !important;
  vertical-align: top;
}

.progress {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.noData {
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.statusCell {
  text-align: center !important;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center
}
