@import "../layout.scss";

.root {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.autoComplete {
  position: relative;
  z-index: 99;
}

.leftColumn {
  background-color: #fff;
  height: 100%;
  width: 450px;
  border-right: 1px solid #e0e0e0;
  position: fixed;
  left: 0;
  top: inherit;
  overflow: auto;
  padding-bottom: 150px;

  h2 {
    padding-bottom: 0 !important; 
  }
}

.rightColumn {
  background-color: #fafbfe;
  width: 100%;
  padding: 0 0 56px 450px;
}

.footer {
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
}

.cell {
  display: flex;
}

.icon {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 15px 0;
  padding-right: 15px;
  padding-top: 35px;
  min-width: 40px;
  height: 80px;
}

.field {
  width: calc(100% - 80px);
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 10px;
  flex-direction: unset !important;
}

.tabs {
  padding: 10px 50px 0 50px;
}

.content {
  padding: 20px 0 54px 0;
}

.dob {
  font-size: 70%;
  display: block;
}
