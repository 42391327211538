@import '../variables.scss';

.root{
  width: 320px;
  background-color: #fff;
}

.innerDrawer {
  position: relative !important;
}

.drawerNav {
  background-color: inherit;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 !important;
}

.listItemTrailing {
  right: 24px !important;
}

.version {
  position: fixed;
  bottom: 0;
  left: 0;
  color: #aaa;
  padding-left: 5px;
  padding-bottom: 5px;
}

.route, .activeRoute {
  padding-left: 15px !important;
  padding-bottom: 10px !important;
}

.activeRoute {
  background-color: rgba(0, 0, 0, 0.08) !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.16) !important;
  }
}

.activeRoute > .text > * {
  font-weight: 600 !important;
}

.activeRoute path {
  color: #448aff !important;
}

.subHeader {
  display: flex;  
  padding-left: 10px !important;
  span {
    font-weight: 600;
    font-size: 14px;
  }
}

.text {
  font-size: 13px;
  > span {
    font-size: 13px !important;
  }
}

@media (max-width: 800px) {
  .root, .contents {
    width: 70px;
  }
  .text {
    display: none;
  }

  .header span {
    font-size: 12px;
  }

  .route, .activeRoute {
    padding-left: 10px !important;
    padding-bottom: 10px !important;
  }
}

