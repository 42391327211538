@import "../layout.scss";

.root {
  @extend .root;
  height: calc(100% - 66px);
  overflow: unset;
  position: fixed;
  z-index: 9999;
}

.profile {
  background: white;
  padding: 24px 24px 56px;
  height: 75%;
  overflow: auto;
}

.title {
  font-weight: 600;
  padding: 20px 0 0 0;
  > button {
    padding: 7px !important;
    border-radius: 20px !important;
    width: 40px !important;
    min-width: 0 !important;
    top: -3px !important;
    path {
      fill: rgb(38, 166, 154);
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-auto-flow: column;
}

.cell {
  display: flex;
}

.icon {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 15px 0;
  padding-right: 15px;
  min-width: 40px;
}

.field {
  width: calc(100% - 80px);
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  height: 60px;
  margin-bottom: 10px;
  flex-direction: unset !important;
}

.notesArea {
  grid-column: 1 /3;
  grid-row: 6;
  z-index: 10;
  padding-right: 40px;
}

.actions {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 99;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  left: 0;
  padding: 10px 20px;
  border-top: 1px solid #e0e0e0;
}

.customMarker {
  background-color: rgb(38, 166, 154);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 6px #aaa, 2px 2px 6px #ddd;
  position: fixed;

  path {
    color: white !important;
  }
}

.lastUpdated {
  float: right;
  margin-top: 15px;
  font-size: 0.85rem;
}
