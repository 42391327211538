@import '../layout.scss';
@import '../variables.scss';

.root{  
  height: 100%;
  width: $app-width;
  margin: auto;
}

header {
  height: $app-bar-height !important;
  width: 100%;
  position: relative;
  z-index: 999;
}

main {
  height: calc(100% - #{$app-bar-height});
  display: flex;
  position: fixed;
  width: 100%;
}