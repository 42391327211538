@import '../variables.scss';
@import '../animations.scss';

.menuTop {  
  position: fixed;
  width: 100%;
  z-index: 1;

  svg {
    @extend .bounceLoad;
    fill: #ffffff; 
  }
}

.toolBar {
  display: grid !important;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
  min-height: 62px !important;
  padding: 0 !important;
}

.separator {
  background-color: rgba(255, 255, 255, 0.7) !important;
  margin: 3px !important;
}

.title {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  min-width: 120px;
  cursor: pointer;
  background: transparent;
  border: 0 none;
  outline-style: none;
  padding: 0 !important;
}

.actions {
  display: flex;
  grid-column: 12 / 12;
  grid-row: 1 / 1;
  justify-content: flex-end;
  padding-right: 10px;
}

