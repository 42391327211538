.extraHelp {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.odsControl {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.viewGP {
    margin-top: 1rem;
}
.frame {
    padding: 1.5rem;
}

.iconCell {
    display:flex;
    margin-left: 1rem;
    padding: 1rem;
}

.previewTitle{
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.previewDetail{
    margin-bottom:1.5rem;
}
