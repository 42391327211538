@import "../layout.scss";
@import "../variables.scss";

.gpRow {
    .secondary {
	color: $table-body-secondary-color;
    }
}

.noGPs {
    text-align: center;
}

.loading {
    text-align: center;
}

.scrollWrap {
    overflow-y: auto;
    width: 100%;
}
