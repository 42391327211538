.label{
    font-size: 0.82rem;
}

.field{
    font-size: 0.84rem;
    padding: 2px 0 2px 0;
    font-weight:500;
}

.container{
    padding: 0px 0 14px 0;
}

.updated{
    padding-left: 5px;
    font-size: 0.82rem;
}

.currentGP{
    border-right: 0.08rem solid black;
    padding-right: 0.2rem;
}
.updatedGP{
    padding-left: 2.8rem;
}

.title{
    padding: 10px 0 24px 0;
}

.gpDetails{
    padding-top: 20px;
}

.topGrid{
    margin-bottom: 10px;
}

.lastUpdated{
    font-size: 0.8rem;
}

.upToDate{
    margin-left:8px;
    font-size:0.9rem;
}
