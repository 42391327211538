

$app-width: 100%;
$app-bar-height: 66px;
$standard-grey: rgb(250,251,254);
$standard-white: white;
$primary-font-color: #333333;
$primary-color: rgba(0, 0, 0, 0.9);


$table-body-color: #000000;
$table-body-secondary-color: #757575;
$modal-body-secondary-color: #747474;

