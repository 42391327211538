
.linearLoad {
  animation: linearLoadAnimation linear 0.2s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
}

.bounceLoad {
  animation: bounceLoadAnimation linear 0.3s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
}

@keyframes linearLoadAnimation{
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounceLoadAnimation{
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}