@import '../variables.scss';
@import '../animations.scss';

.root {
  grid-column: 2 / 12;
  grid-row: 1 / 1;
}

.search {
  max-width: 600px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  padding: 5px 5px 5px 10px;
  height: auto;
  display: flex;
  align-items: center;

  input {
    color: white !important;
  }
}

.searchIcon {
  margin-right: 10px;
  transform: scale(1.1);
  top: 4px;
  position: relative;
}

.menuItem{
  align-items: inherit !important;
}